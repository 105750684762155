<template>
  <div>
    <div class="background-animation"></div>
    <div class="loader" v-if="isLoading">
        <img src="@/assets/loader.gif">
    </div>

    <!-- Main Content -->
    <div class="container mb-100">

        <!-- Signup -->
        <section v-if="1 != 1" class="row position-relative mt-200">
        
            <div class="col-12 col-xl-5 mx-auto text-center">
                <h5 class="head-title w-md-50 mx-auto mb-2 fs-2">
                    <span class="text-custom-primary">დავით კენჭაძის</span><br>
                    აკადემიის გუნდს გვინდა
                </h5>
                <p class="w-md-50 mx-auto mb-4">რომ თქვენ გახდეთ წარმატებული პროფესიონალი, თქვენი თავის საუკეთესო ვერსია და ვდებთ პირობას რომ ამისთვის ყველაფერს გავაკეთებთ.</p>
                <!-- Signup -->
                <!-- <router-link to="/Signup" class="btn btn-my-primary placeholder-wave">  
                            
                    <div class="d-flex column-gap-2 position-relative">
                        დარეგისტრირდი
                        <i class="bi bi-rocket"></i>
                        <div class="fire">
                            <div class="red flame"></div>
                            <div class="orange flame"></div>
                            <div class="yellow flame"></div>
                            <div class="white flame"></div>
                        </div>
                    </div>
                </router-link> -->
                <!-- End Signup -->
            </div>
        </section>
        <!-- End Signup -->

        <!-- Divider -->
        <div id="programs" class="pt-100 pb-3"></div>
        <!-- End Divider -->

        <!-- What do you offer? - Row -->
        <section v-if="1 != 1" class="row g-4">
            <!-- Col -->
            <div class="col-md-12 mb-0 mb-md-2">
                <h5 class="head-title m-0">რატომ ჩვენ?</h5>
            </div>
            <!-- End Col -->

            <!-- Col -->
            <div v-for="(item, index) in reasons" :key="index" class="col-md-4">
               <div class="card shadow-sm border-0 shadow-sm border-0 rounded-4 h-100">
                <div class="card-body">
                    <div class="d-flex align-items-center gap-3 mb-3">
                        <div :class="item.badge" class="rounded-circle text-white d-flex align-items-center justify-content-center w-100" style="max-width: 50px;height:50px;"><i class="bi bi-rocket-takeoff"></i></div>
                        <h6 class="m-0">{{ item.name }}</h6>
                    </div>
                    <p>{{ item.description }}</p>
                </div>
               </div>
            </div>
            <!-- End Col -->

        </section>
        <!-- End What do you offer? - Row -->

        <!-- About us - Row -  -->
        <section v-if="1 != 1" class="row g-4 mt-5 mb-md-100">
            <div class="col-md-12 mb-4">
                <h5 class="head-title">ჩვენს შესახებ</h5>
            </div>
            <div class="col-md-5">
                <img src="../assets/image/about-us.gif" alt="" class="img-fluid w-100 border rounded">
            </div>
            <div class="col-md-7">
                <h5>ვინ ვართ ჩვენ?</h5>
                <p> ჩვენ ვართ ტექნიკური ენთუზიასტების, მასწავლებლებისა და ინდუსტრიის პროფესიონალების გუნდი, რომლებსაც სჯერათ ტექნოლოგიების განათლების ტრანსფორმაციული ძალის. ჩვენი მრავალფეროვანი გამოცდილება გვაძლევს საშუალებას შემოგთავაზოთ კურსებისა და პროგრამების ფართო სპექტრი, რომლებიც ემსახურებიან სხვადასხვა ინტერესებსა და უნარებს. </p>
                <h5>რას გთავაზობთ ჩვენ?</h5>
                <p> ხართ თუ არა სრული დამწყები, რომელიც ეძებთ თქვენი ფეხის თითების განვითარებას ტექნოლოგიების სამყაროში ან გამოცდილი პროფესიონალი, რომელიც ცდილობს გააფართოვოს თქვენი ცოდნა, ჩვენ გვაქვს თქვენთვის რაღაც. ჩვენი ყურადღებით შემუშავებული კურსები მოიცავს ისეთ თემებს, როგორიცაა კოდირება, ვებ განვითარება, მონაცემთა მეცნიერება, ხელოვნური ინტელექტი, კიბერუსაფრთხოება და მრავალი სხვა. </p>
            </div>
        </section>
        <!-- End About us - Row -->

         <!-- Our Courses - Row -->
        <section class="row g-4">
            <div class="col-md-12 mb-0 mb-md-2">
                <h5 class="head-title m-0">პროგრამები</h5>
            </div>

            <!-- Col -->
            <div v-for="(item, index) in courses" :key="index" class="col-md-4">
                <div class="card shadow-sm border-0 rounded-4 bordered p-4">
                    <img class="card-img" :src="item.file" alt="Front-end">
                    <h5 class="card-name mt-4">{{ item.name }}</h5>
                    <p class="card-desc pb-1" v-html="item.short_desc"></p>
                    <!-- <router-link :to="'/Course/'+item.name+'/'+item.id" class="btn btn-my-primary placeholder-wave">   -->
                    <button @click="dynamicRoute(item.name, item.id)" class="btn btn-my-primary placeholder-wave">  
                        <div class="d-inline-flex column-gap-2 position-relative">
                            სრულად ნახვა
                            <i class="bi bi-rocket"></i>
                            <div class="fire">
                                <div class="red flame"></div>
                                <div class="orange flame"></div>
                                <div class="yellow flame"></div>
                                <div class="white flame"></div>
                                <!-- <div class="blue circle"></div> -->
                                <!-- <div class="black circle"></div> -->
                            </div>
                        </div>
        
                    </button>
                </div>
            </div>
            <!-- End Col -->

        </section>
        <!-- End Our Courses - Row -->

        <!-- Sounds -->
        <!-- <audio v-if="audio_1" style="position: absolute;visibility: hidden;" class="test" autoplay controls>
            <source src="../assets/sounds/Rocket_Launch.mp3">
        </audio> -->
        <!-- <audio v-if="audio_2" style="position: absolute;visibility: hidden;" class="test" autoplay controls>
            <source src="../assets/sounds/ouch.mp3">
        </audio> -->
        <!-- End Sounds -->
        
    </div>
    <!-- End Main Content -->
  </div>
</template>

<script>
// Import Swiper Vue.js components
// import { Swiper, SwiperSlide } from 'swiper/vue';

// // Import Swiper styles
// import 'swiper/css';

// import 'swiper/css/pagination';

// // import required modules
// import { Autoplay, Navigation, Pagination } from 'swiper/modules';

// Axios
import axios from 'axios';

// JSON Reasons
import Reasons from '../JSONS/reasons.json';

export default {
    name: "Content",
    data() {
        return {
            courses: [],
            audio_1: false,
            // audio_2: false
            reasons: Reasons,
            isLoading: true
        }
    },
    components: {
        // Swiper,
        // SwiperSlide,
    },
    methods: {
        getCourses() {
            axios.get(process.env.VUE_APP_BACKEND_URL + '/auth/traning/LoadData')
                .then((res) => {
                    this.courses = res.data;

                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        play() {
            this.audio_1 = true;
            // this.audio_2 = false;
        },
        stop() {
            this.audio_1 = false;
            // this.audio_2 = true;
            // setTimeout(() => {
            //     this.audio_2 = false
            // }, 2000)
        },

        dynamicRoute(name, id) {

            // Replace "%20" with underscores
            // var item_name = name.replace(/ /g, "-");

            this.$router.push({
                path: `/Course/${id}`
            })
        }
    },
    mounted() {
        // for animation
        // setTimeout(() => {
        //     const btn = document.querySelectorAll('.btn-my-primary');
        //     btn.forEach(e => {
        //         e.addEventListener('mouseenter', this.play);
        //         e.addEventListener('mouseleave', this.stop);
        //     })
        // }, 1000);

        // scroll to the top
        window.scrollTo(0, 0);

        // get data of courses
        this.getCourses();
    },
    updated() {
        // let audio = document.querySelectorAll("audio");
        // audio.forEach(e => {
        //     e.volume = 0.05
        // })
    },
    // setup() {
    //     return {
    //         modules: [Pagination, Navigation, Autoplay],
    //     };
    // }
}
</script>

<style scoped>
.card button i {
    font-size: inherit;
}

.accordion-button:not(.collapsed) {
    background-color: #fff;
}

</style>